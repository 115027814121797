// Variables
@use "../../variables/variables" as *;

// Module
.contactForm {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    &__label {
        display: block;
        margin-bottom: 10px;
        font-size: $font-size-s;
    }

    &__star {
        color: $color-fifth;
        font-size: $font-size-m;
    }

    &__field {
        width: 100%;
        padding: 10px;
        border: 1px solid $color-sixth;
        border-radius: $border-radius-base;
        font-family: OpenSans-Light, serif;
        font-size: $font-size-s;

        &--textArea {
            min-height: 150px;
        }
    }

    &__error {
        display: block;
        max-height: 0;
        margin-top: 0;
        opacity: 0;
        color: $color-fifth;
        font-size: $font-size-s;
        line-height: $line-height-s;

        &--visible {
            max-height: 45px;
            margin-top: 5px;
            transition: all 300ms ease-in-out;
            opacity: 1;
        }
    }

    &__submit {
        text-align: center;
    }

    &__button {
        padding: 10px 60px;
        border: inherit;
        border-radius: $border-radius-base;
        background-color: $color-fourth;
        font-family: OpenSans-Light, serif;
        font-size: $font-size-m;
    }
}
