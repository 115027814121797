// Variables
@use "../../variables/variables" as *;

// Module
.nav {
    margin-top: 60px;
    padding: 0 10px;

    @media all and (width >= 576px) {
        max-width: 540px;
        margin: 60px auto;
        padding: 0;
    }

    @media all and (width >= 768px) {
        max-width: 720px;
    }

    @media all and (width >= 992px) {
        max-width: 960px;
    }

    @media all and (width >= 1200px) {
        max-width: 1140px;
    }

    @media all and (width >= 1400px) {
        max-width: 1320px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        font-size: $font-size-m;
    }

    &__sublist {
        padding-left: 20px;
        font-size: $font-size-m;

        &--display {
            height: 0;
            margin-top: 0;
            transition: all 300ms ease-in-out;
            opacity: 0;
        }
    }

    &__link {
        display: block;
    }

    &__item {
        line-height: 2;

        &--hovered:hover {
            .nav__sublist--display {
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                height: 20px;
                margin-top: 30px;
                opacity: 1;
            }
        }
    }
}
