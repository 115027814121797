// Variables
@use "../variables/variables" as *;

// Base
@font-face {
    font-family: OpenSans-LightItalic;
    src: url("./fonts/OpenSans/OpenSans-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: OpenSans-Light;
    src: url("./fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
}

html {
    font-size: $font-size-base;
}

body {
    background-color: $color-base;
    color: $color-first;
    font-family: OpenSans-Light, serif;
}

* {
    &,
    &::before,
    &::after {
        box-sizing: border-box;
    }
}

img {
    max-width: 100%;    /* Responsive images */
    height: auto;       /* by default */
    border: 0;
}

input {
    appearance: none;
}

a {
    color: $color-second;
    text-underline-offset: 5px;
}

p {
    color: $color-first;
    font-size: $font-size-m;
    line-height: $line-height-m;
    text-align: justify;
}

strong {
    font-weight: bold;
}

h1,
h2,
h3 {
    line-height: $line-height-m;
}

h1 {
    color: $color-first;
    font-family: OpenSans-LightItalic, serif;
    font-size: $font-size-xxl;
}

h2 {
    color: $color-second;
    font-family: OpenSans-LightItalic, serif;
    font-size: $font-size-xl;
}

h3 {
    color: $color-third;
    font-size: $font-size-l;
}
