// Variables
@use "../../variables/variables" as *;

// Module
.member {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;

    @media all and (width >= 1200px) {
        max-width: 45%;
    }

    &__image {
        width: 130px;
        border-radius: $border-radius-image;
    }

    &__caption {
        color: $color-third;
        font-size: $font-size-l;
        line-height: $line-height-m;
        text-align: center;
    }

    &__description {
        font-family: OpenSans-LightItalic, serif;
        font-size: $font-size-m;
        line-height: $line-height-m;
        text-align: justify;
    }
}
