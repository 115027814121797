// Variables
@use "../../variables/variables" as *;

// Module
.header {
    position: sticky;
    z-index: 90;
    top: 0;
    height: 110px;
    overflow: hidden;
    transition: height 400ms ease-in-out;
    background-color: $color-fourth;

    &--open {
        width: 100%;
        height: 1000px;
        overflow: auto;
        transition: height 300ms ease-in-out;
    }

    &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px 10px;

        @media all and (width >= 576px) {
            max-width: 540px;
            margin: 0 auto;
            padding: 20px 0;
        }

        @media all and (width >= 768px) {
            max-width: 720px;
        }

        @media all and (width >= 992px) {
            max-width: 960px;
        }

        @media all and (width >= 1200px) {
            max-width: 1140px;
        }

        @media all and (width >= 1400px) {
            max-width: 1320px;
        }
    }

    &__link {
        width: 116px;
        margin-left: 20px;
    }

    &__logo {
        display: block;
    }
}
