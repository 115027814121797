.layout {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    row-gap: 140px;

    @media all and (width >= 576px) {
        max-width: 540px;
        margin: 0 auto;
        padding: 20px 0;
    }

    @media all and (width >= 768px) {
        max-width: 720px;
    }

    @media all and (width >= 992px) {
        max-width: 960px;
    }

    @media all and (width >= 1200px) {
        max-width: 1140px;
    }

    @media all and (width >= 1400px) {
        max-width: 1320px;
    }

    &__section {
        display: flex;
        flex-direction: column;
        row-gap: 60px;

        &--grid {
            @media all and (width >= 1200px) {
                flex-flow: row wrap;
                justify-content: space-between;
            }
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
}
