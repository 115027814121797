// Variables
@use "../../variables/variables" as *;

// Module
.footer {
    position: absolute;
    width: 100%;
    background-color: $color-fourth;

    &__container {
        display: flex;
        justify-content: space-between;
        padding: 20px 10px;

        @media all and (width >= 576px) {
            max-width: 540px;
            margin: 0 auto;
            padding: 20px 0;
        }

        @media all and (width >= 768px) {
            max-width: 720px;
        }

        @media all and (width >= 992px) {
            justify-content: center;
            max-width: 960px;
            column-gap: 250px;
        }

        @media all and (width >= 1200px) {
            max-width: 1140px;
        }

        @media all and (width >= 1400px) {
            max-width: 1320px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        justify-content: center;
    }

    &__element {
        font-size: $font-size-s;

        &--rights {
            line-height: $line-height-s;
            text-align: center;
        }
    }

    &__logo {
        width: 90px;
    }

    &__link {
        display: block;
        text-align: center;
    }
}
