// Variables
@use "../../variables/variables" as *;

// Module
.toggle {
    position: relative;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__text {
        margin-bottom: 5px;
        color: $color-first;
        font-size: $font-size-s;
    }

    &__icon {
        width: 30px;
        height: 30px;

        &--bars {
            position: relative;
            transform: scale(1);
            transition: transform 300ms ease-in-out;
        }

        &--cross {
            position: absolute;
            top: 18px;
            transform: scale(0);
            transition: transform 300ms ease-in-out;
        }

        &--close {
            position: relative;
            top: 0;
            transform: scale(1);
        }

        &--open {
            position: absolute;
            top: 17px;
            transform: scale(0);
        }
    }
}
