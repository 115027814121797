// Variables
@use "../../variables/variables" as *;

// Module
.faq {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px 10px;
    background-color: $color-sixth;

    &__title {
        margin: 0;
        color: $color-third;
        font-size: $font-size-l;
        font-weight: normal;
        line-height: $line-height-m;
    }

    &__paragraph {
        font-size: $font-size-m;
        line-height: $line-height-m;
        text-align: justify;
    }
}
