html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: OpenSans-LightItalic;
  src: url("OpenSans-LightItalic.e3963f62.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans-Light;
  src: url("OpenSans-Light.ddfdb137.ttf") format("truetype");
}

html {
  font-size: 62.5%;
}

body {
  color: #000;
  background-color: #fff;
  font-family: OpenSans-Light, serif;
}

*, :before, :after {
  box-sizing: border-box;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

input {
  appearance: none;
}

a {
  color: #074173;
  text-underline-offset: 5px;
}

p {
  color: #000;
  text-align: justify;
  font-size: 2rem;
  line-height: 2;
}

strong {
  font-weight: bold;
}

h1, h2, h3 {
  line-height: 2;
}

h1 {
  color: #000;
  font-family: OpenSans-LightItalic, serif;
  font-size: 3rem;
}

h2 {
  color: #074173;
  font-family: OpenSans-LightItalic, serif;
  font-size: 2.5rem;
}

h3 {
  color: #1679ab;
  font-size: 2rem;
}

.layout {
  flex-direction: column;
  row-gap: 140px;
  padding: 20px 10px;
  display: flex;
}

@media (width >= 576px) {
  .layout {
    max-width: 540px;
    margin: 0 auto;
    padding: 20px 0;
  }
}

@media (width >= 768px) {
  .layout {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .layout {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .layout {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .layout {
    max-width: 1320px;
  }
}

.layout__section {
  flex-direction: column;
  row-gap: 60px;
  display: flex;
}

@media (width >= 1200px) {
  .layout__section--grid {
    flex-flow: wrap;
    justify-content: space-between;
  }
}

.layout__inner {
  flex-direction: column;
  row-gap: 30px;
  display: flex;
}

.toggle {
  position: relative;
}

.toggle__container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.toggle__text {
  color: #000;
  margin-bottom: 5px;
  font-size: 1.6rem;
}

.toggle__icon {
  width: 30px;
  height: 30px;
}

.toggle__icon--bars {
  transition: transform .3s ease-in-out;
  position: relative;
  transform: scale(1);
}

.toggle__icon--cross {
  transition: transform .3s ease-in-out;
  position: absolute;
  top: 18px;
  transform: scale(0);
}

.toggle__icon--close {
  position: relative;
  top: 0;
  transform: scale(1);
}

.toggle__icon--open {
  position: absolute;
  top: 17px;
  transform: scale(0);
}

.header {
  z-index: 90;
  background-color: #e0edde;
  height: 110px;
  transition: height .4s ease-in-out;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.header--open {
  width: 100%;
  height: 1000px;
  transition: height .3s ease-in-out;
  overflow: auto;
}

.header__container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  display: flex;
}

@media (width >= 576px) {
  .header__container {
    max-width: 540px;
    margin: 0 auto;
    padding: 20px 0;
  }
}

@media (width >= 768px) {
  .header__container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .header__container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .header__container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .header__container {
    max-width: 1320px;
  }
}

.header__link {
  width: 116px;
  margin-left: 20px;
}

.header__logo {
  display: block;
}

.member {
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  display: flex;
}

@media (width >= 1200px) {
  .member {
    max-width: 45%;
  }
}

.member__image {
  border-radius: 80px;
  width: 130px;
}

.member__caption {
  color: #1679ab;
  text-align: center;
  font-size: 2rem;
  line-height: 2;
}

.member__description {
  text-align: justify;
  font-family: OpenSans-LightItalic, serif;
  font-size: 2rem;
  line-height: 2;
}

.footer {
  background-color: #e0edde;
  width: 100%;
  position: absolute;
}

.footer__container {
  justify-content: space-between;
  padding: 20px 10px;
  display: flex;
}

@media (width >= 576px) {
  .footer__container {
    max-width: 540px;
    margin: 0 auto;
    padding: 20px 0;
  }
}

@media (width >= 768px) {
  .footer__container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .footer__container {
    justify-content: center;
    column-gap: 250px;
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .footer__container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .footer__container {
    max-width: 1320px;
  }
}

.footer__list {
  flex-direction: column;
  justify-content: center;
  row-gap: 30px;
  display: flex;
}

.footer__element {
  font-size: 1.6rem;
}

.footer__element--rights {
  text-align: center;
  line-height: 1.5;
}

.footer__logo {
  width: 90px;
}

.footer__link {
  text-align: center;
  display: block;
}

.title {
  text-align: center;
}

.nav {
  margin-top: 60px;
  padding: 0 10px;
}

@media (width >= 576px) {
  .nav {
    max-width: 540px;
    margin: 60px auto;
    padding: 0;
  }
}

@media (width >= 768px) {
  .nav {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .nav {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .nav {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .nav {
    max-width: 1320px;
  }
}

.nav__list {
  flex-direction: column;
  row-gap: 30px;
  font-size: 2rem;
  display: flex;
}

.nav__sublist {
  padding-left: 20px;
  font-size: 2rem;
}

.nav__sublist--display {
  opacity: 0;
  height: 0;
  margin-top: 0;
  transition: all .3s ease-in-out;
}

.nav__link {
  display: block;
}

.nav__item {
  line-height: 2;
}

.nav__item--hovered:hover .nav__sublist--display {
  opacity: 1;
  flex-direction: column;
  row-gap: 30px;
  height: 20px;
  margin-top: 30px;
  display: flex;
}

.contactForm {
  flex-direction: column;
  row-gap: 30px;
  display: flex;
}

.contactForm__label {
  margin-bottom: 10px;
  font-size: 1.6rem;
  display: block;
}

.contactForm__star {
  color: #eb5353;
  font-size: 2rem;
}

.contactForm__field {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  font-family: OpenSans-Light, serif;
  font-size: 1.6rem;
}

.contactForm__field--textArea {
  min-height: 150px;
}

.contactForm__error {
  opacity: 0;
  color: #eb5353;
  max-height: 0;
  margin-top: 0;
  font-size: 1.6rem;
  line-height: 1.5;
  display: block;
}

.contactForm__error--visible {
  opacity: 1;
  max-height: 45px;
  margin-top: 5px;
  transition: all .3s ease-in-out;
}

.contactForm__submit {
  text-align: center;
}

.contactForm__button {
  border: inherit;
  background-color: #e0edde;
  border-radius: 5px;
  padding: 10px 60px;
  font-family: OpenSans-Light, serif;
  font-size: 2rem;
}

.introduction {
  flex-direction: column;
  align-items: center;
  row-gap: 60px;
  display: flex;
}

@media (width >= 1200px) {
  .introduction {
    row-gap: inherit;
    flex-direction: row;
    justify-content: space-between;
  }
}

.introduction__header {
  text-align: center;
}

@media (width >= 1200px) {
  .introduction__header {
    text-align: justify;
    max-width: 50%;
  }
}

.introduction__image {
  border-radius: 5px;
  width: 60%;
}

@media (width >= 576px) {
  .introduction__image {
    width: 50%;
  }
}

@media (width >= 768px) {
  .introduction__image {
    width: 40%;
  }
}

@media (width >= 992px) {
  .introduction__image {
    width: 30%;
  }
}

.faq {
  background-color: #dcdcdc;
  flex-direction: column;
  row-gap: 10px;
  padding: 20px 10px;
  display: flex;
}

.faq__title {
  color: #1679ab;
  margin: 0;
  font-size: 2rem;
  font-weight: normal;
  line-height: 2;
}

.faq__paragraph {
  text-align: justify;
  font-size: 2rem;
  line-height: 2;
}

.proposition {
  flex-direction: column;
  align-items: center;
  row-gap: 60px;
  display: flex;
}

@media (width >= 1200px) {
  .proposition {
    row-gap: inherit;
    flex-direction: row;
    justify-content: space-between;
  }
}

.proposition__header {
  text-align: center;
  flex-direction: column;
  row-gap: 30px;
  display: flex;
}

@media (width >= 1200px) {
  .proposition__header {
    text-align: justify;
    max-width: 50%;
  }
}

.proposition__image {
  border-radius: 5px;
  width: 60%;
}

@media (width >= 576px) {
  .proposition__image {
    width: 50%;
  }
}

@media (width >= 768px) {
  .proposition__image {
    width: 40%;
  }
}

@media (width >= 992px) {
  .proposition__image {
    width: 30%;
  }
}
/*# sourceMappingURL=index.f62647e2.css.map */
