// Variables
@use "../../variables/variables" as *;

// Module
.proposition {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    align-items: center;

    @media all and (width >= 1200px) {
        flex-direction: row;
        row-gap: inherit;
        justify-content: space-between;
    }

    &__header {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        text-align: center;

        @media all and (width >= 1200px) {
            max-width: 50%;
            text-align: justify;
        }
    }

    &__image {
        width: 60%;
        border-radius: $border-radius-base;

        @media all and (width >= 576px) {
            width: 50%;
        }

        @media all and (width >= 768px) {
            width: 40%;
        }

        @media all and (width >= 992px) {
            width: 30%;
        }
    }
}
